<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Categories ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.category.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Category
      </v-btn>
    </v-app-bar>

    <div class="mx-4" id="category-list">
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search category"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingAction"
              height="40px"
              class="ml-10 bulk-btn"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title
                @click="item.permission && actionHandel(item.value)"
                :style="{
                  cursor: item.permission ? 'pointer' : 'not-allowed',
                  color: item.permission ? 'black' : 'gray',
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="categories"
        v-model="selected"
        :loading="loading"
        show-select
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @update:options="fetchCategories"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
        :mobile-breakpoint="960"
      >
        <template #body="props">
          <draggable
            :list="categories"
            tag="tbody"
            @end="(event) => onDropCallback(event, options)"
            :move="(event) => onMoveCallback(event, options)"
          >
            <data-table-row-handler
              v-for="(item, index) in props.items"
              :key="index"
              :item="item"
              :headers="headers"
              @click="editItem(item)"
              :show-select="true"
              :selected="selected.includes(item)"
              @toggle-selected="toggleSelection"
            >
              <!-- add it into data-table-row-handler demo -->
              <!-- :show-select="true"
              :selected="selected.includes(item)"
              @toggle-selected="toggleSelection" -->
              <template #item.is_active="{ item }">
                <v-checkbox
                  v-model="item.is_active"
                  disabled
                  readonly
                  class="mt-0 mb-0"
                />
              </template>
              <template #item.buildingName="{ item }">
                <div
                  v-for="buildingItem in item.buildings"
                  :key="buildingItem.id"
                >
                  {{ buildingItem.name }}
                </div>
              </template>
              <template #item.created_at="{ item }">
                <div v-if="item.addedDate">{{ item.addedDate }}</div>
              </template>
              <template #item.updated_at="{ item }">
                <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
              </template>
            </data-table-row-handler>
          </draggable>
        </template>
      </v-data-table>
    </div>
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkActions('delete')
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Category list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/common/DataTableRowHandler.vue'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import {
  sortFilter,
  perPageFilter,
  messageConfirmDelete,
} from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'CategoriesPage',

  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    draggable,
    DataTableRowHandler,
    ConfirmModal,
  },

  data() {
    return {
      loading: false,
      loadingAction: false,
      selected: [],
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      options: {},
      defaultFilter: '',
      sortOrder: false,
      defaultStatus: 0,
      tabItems: [
        {
          text: 'ACTIVE',
          value: 0,
        },
        {
          text: 'INACTIVE',
          value: 1,
        },
      ],
      filterBy: [
        {
          value: 'label',
          label: 'Category',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
        {
          value: 'updated_at',
          label: 'Updated date',
        },
      ],
      headers: [
        {
          text: 'Category',
          value: 'label',
          sortable: false,
        },
        {
          text: 'Building(s)',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Active',
          value: 'is_active',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
          sortable: false,
        },
        {
          text: 'Added date',
          value: 'created_at',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
          permission: true,
        },
      ],
      itemsPerPageTables: 25,
      showBulkDeleteModal: false,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
      listMeta: (state) => state.category.listMeta,
      initCategories: (state) => state.category.initList,
      paramsCategory: (state) => state.category.queryParams,
      scrollPosition: (state) => state.category.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      updatePositions: 'category/updatePositions',
      bulkActionCategory: 'category/bulkActionCategory',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'category/setQueryParams',
      setScrollPosition: 'category/setScrollPosition',
    }),

    fetchInitParams() {
      if (!this.paramsCategory) {
        this.fetchCategories()
        return
      }
      if (this.paramsCategory?.filter) {
        this.defaultStatus = this.paramsCategory.filter
      }

      if (this.paramsCategory?.itemsPerPage) {
        const { itemsPerPage } = this.paramsCategory
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsCategory?.search) {
        this.filter.search = this.paramsCategory.search
      }

      this.fetchCategories(null, 1, {
        ...this.paramsCategory,
        type: CATEGORY_TYPE.general,
        paginate: true,
      })
    },

    activeAction() {
      this.clearCategories()
      this.fetchCategories()
    },

    filterList(filterValue) {
      this.clearCategories()
      this.fetchCategories(null, filterValue)
    },

    search: debounce(function () {
      this.fetchCategories()
    }, 600),

    handleTabClick(status) {
      this.itemsPerPageTables = 25
      this.defaultStatus = status
      this.defaultFilter = ''
      this.fetchCategories()
    },

    async fetchCategories(options, page = 1, paramsCategory = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalItems,
          this.itemsPerPageTables
        ),
        filter: this.defaultStatus,
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'position'
        ),
        paginate: true,
        type: CATEGORY_TYPE.general,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearCategories()
      this.loading = true
      await this.getCategories(paramsCategory || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    async changePositions(items) {
      await this.updatePositions({ items: items }).then((data) => {
        this.showSnackbar('Updated positions successfully!')
      })
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.category.details',
        params: { id: item.id },
      })
    },

    onMoveCallback(event, options) {
      return !options?.sortBy?.length
    },

    onDropCallback(evt, options) {
      const fromIndex = evt.oldIndex // Original index of the dragged item
      const toIndex = evt.newIndex

      if (!!options?.sortBy?.length || fromIndex === toIndex) {
        return false
      }

      // Extract positions from the original list
      const sortedPositions = this.initCategories.map((item) => item.position)

      // Map over the new list and reassign positions
      const listResult = this.categories.map((item, index) => ({
        id: item.id,
        position: sortedPositions[index],
      }))
      this.changePositions(listResult)
      return true
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select categories', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('ids[]', property.id)
      })

      await this.bulkActionCategory(formData)
        .then(() => {
          this.showSnackbar('Bulk successfully updated!')
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearCategories()
          this.fetchCategories()
        })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    toggleSelection(item) {
      const index = this.selected.indexOf(item)
      if (index > -1) {
        // Remove item if already selected
        this.selected.splice(index, 1)
      } else {
        // Add item if not selected
        this.selected.push(item)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
